<template>
  <div class="row my-5 container-center mx-auto p-2" style="text-align:justify">
    <div class="col-12">
      <BtnVoltar />
    </div>
    <div class="col-12 fs-2">Our Story</div>
    <div class="col-6 mt-2 fs-6">
      <img src="../../assets/img/ramzi-dalloul.jpg" class="w-100 img-fluid" />
    </div>
    <div class="col-6 mt-2 fs-6">
      <img src="../../assets/img/saeda-dalloul.jpg" class="w-100 img-fluid" />
    </div>
    <div class="col-12 mt-2 fs-6">My late father, Dr. Ramzi Dalloul and my late mother, Saeda El Husseini Dalloul, always had a strong sense of culture. They began collecting art in the early 70’s, buying art they loved, made by artists they got to know intimately well, most of whom shared aesthetic, ethical, philosophical, and political views with them. My parents considered artists the keepers of truth and archivists of history.</div>
    <div class="col-12 mt-2 fs-6">After over 50 years of collecting art during their extensive travels throughout the Arab world, my late father asked me to organize his and my late mother’s now massive collection and share it with the world, but especially the people and children of the Arab world. The responsibility of institutionalizing their collection was now mine. My mother and father both loved the arts in all its forms, so it is appropriate that this institution for Arab art and culture bares their names.</div>
    <div class="col-12 mt-2 fs-6">The Ramzi and Saeda Dalloul Art Foundation (DAF) is a Beirut-based visual arts institution dedicated to making modern and contemporary Arab art accessible to local and international audiences through archiving, exhibitions, education, publications, public programs, and research. Its aim is to preserve and disseminate its permanent collection which includes works in drawing, installation, mixed-media, painting, photography, ceramics, and sculpture.</div>
    <div class="col-12 mt-2 fs-6">Providing a comprehensive survey of visual arts from most of the Arab region, DAF’s extensive and massive collection reflects the diversity and boldness of Arab creativity, inspired by the complex realities of the region.</div>
    <div class="col-12 mt-2 fs-6">Since late 2017, DAF has been made accessible to the public through its presence on social media where it highlights the history of Arab art weekly. Researchers and doctoral students can also gain access to the foundation's extensive resources, collection management system, and in-house research, in order to further their studies. DAF also organizes weekly visits to its extensive storage galleries for schools, universities and other interested groups. The foundation also provides loans to local and international museums.</div>
    <div class="col-12 mt-2 fs-6">This website is a culmination of almost three years worth of work and should be considered a work in progress. DAF is essentially a research institute, creating a reference for Arab art, and as such we will continue to add features, information and materials about our pioneering artists, so please come and visit us often or become a member and have this information pushed to you as it comes available. In the meantime, enjoy our site and the massive collection it now hosts.</div>
    <div class="col-12 fw-bold mt-4 fs-6">Basel Dalloul</div>
    <div class="col-12 text-italic fs-6">Founder/Chairman</div>
    <div class="col-12 fw-bold mt-4 fs-6">TEAM</div>
    <div class="row mt-4">
      <div class="col-md-4 col-12 mt-4">
        <img src="../../assets/img/team/basel-dalloul-managing-director.jpg" class="w-100 img-fluid" />
        <div class="col-12 fw-bold mt-2 text-center">BASEL DALLOUL</div>
        <div class="col-12 text-center fw-light">Founder/Chairman</div>
      </div>
      <div class="col-md-4 col-12 mt-4">
        <img src="../../assets/img/team/salah-lababidi.jpg" class="w-100 img-fluid" />
        <div class="col-12 fw-bold mt-2 text-center">SALAH LABABIDI</div>
        <div class="col-12 text-center fw-light">Managing Director</div>
      </div>
      <div class="col-md-4 col-12 mt-4">
        <img src="../../assets/img/team/wafa-roz-head-of-research.jpg" class="w-100 img-fluid" />
        <div class="col-12 fw-bold mt-2 text-center">WAFA ROZ</div>
        <div class="col-12 text-center fw-light">Head of Research</div>
      </div>
      <div class="col-md-4 col-12 mt-4">
        <img src="../../assets/img/team/christine-labban-design-communications-manager.jpg" class="w-100 img-fluid" />
        <div class="col-12 fw-bold mt-2 text-center">CHRISTINE LABBAN</div>
        <div class="col-12 text-center fw-light">Design & Communications Manager</div>
      </div>
      <div class="col-md-4 col-12 mt-4">
        <img src="../../assets/img/team/soha-youssef.jpg" class="w-100 img-fluid" />
        <div class="col-12 fw-bold mt-2 text-center">SOHA YOUSSEF</div>
        <div class="col-12 text-center fw-light">Researcher</div>
      </div>
      <div class="col-md-4 col-12 mt-4">
        <img src="../../assets/img/team/via.jpg" class="w-100 img-fluid" />
        <div class="col-12 fw-bold mt-2 text-center">VIA AZZI</div>
        <div class="col-12 text-center fw-light">Assistant Researcher</div>
      </div>
      
    </div>
  </div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'

  export default {
    name: 'ArticlesPage',
    components: { BtnVoltar },
    props: {
      msg: String
    }, data() {
        return {
          
        }
      }
  }
</script>