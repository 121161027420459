<template>
  <div class="row my-5 container-center mx-auto p-2" style="text-align:justify">
    <div class="col-12">
    </div>
  </div>
  <h1>TOKEN FIREBASE</h1>
  <div id="container">
    <strong>FCM Token</strong><br>
    <ion-button @click="showToken()">Show token</ion-button>
    <p>{{ token }}</p>
  </div>
</template>


<script>



import { PushNotifications } from '@capacitor/push-notifications';

const addListeners = async () => {

  await PushNotifications.addListener('registration', token => {
    console.info('Registration token 2: ', token.value);
    window.localStorage.setItem('token', token.value)
    console.info('Registration token local storage: ', token.value);
  });

  await PushNotifications.addListener('registrationError', err => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });

}

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
}


export default {
  name: 'TokenPage',
  components: {
    // IonContent,
    // IonHeader,
    // IonPage,
    // IonTitle,
    // IonToolbar
  },
  data() {
    return {
      fcm: '',
      token: 'Indefinido',
    }
  },
  mounted() {

    addListeners();
    registerNotifications();
    getDeliveredNotifications();

    this.token = window.localStorage.getItem('token')

  },
  methods: {
    async showToken() {

      this.token = window.localStorage.getItem('token')


      alert(this.token)
    },
  }
}
</script>