<template>
  <div class="col-12 my-5 container-center mx-auto p-2">
    <div class="col-12 fs-2">
      <BtnVoltar />
    </div>
    <div class="col-12 fs-2">Jobs & Opportunities</div>
    <div class="col-12 mt-2 fs-6">DAF is currently fully staffed. We are however actively looking for students interested in internship opportunities where they can gain direct exposure to the foundation collection and assist in research as well as other important tasks. If you are interested in applying for such a role and are passionate about art, please fill out the form below.</div>
    <div class="row mt-4">
      <div class="col-md-12 mt-4">
        <label for="example-text-input" class="form-control-label">Full name</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-12 mt-4">
        <label for="example-text-input" class="form-control-label">Street Address</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" placeholder="Input your address"/>
        <input type="text" id="full_name" v-model="full_name" class="form-control mt-4" placeholder="Address line 2"/>
      </div>
      <div class="col-md-4 col-12 mt-4">
        <label for="example-text-input" class="form-control-label">City</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-4 col-12 mt-4">
        <label for="example-text-input" class="form-control-label">State</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-4 col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Country</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-4">
        <label for="example-text-input" class="form-control-label">E-mail address</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Phone Number</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Schedule Availabiility</label>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">Monday</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">Tuesday</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">Wednesday</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">Thursday</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">Friday</label>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Internship Start</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Internship End</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Education degree received</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Please tell us about who you are, your goals and your skills</label>
        <textarea id="full_name" v-model="full_name" class="form-control" />
      </div>
    </div>
  </div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'

  export default {
    name: 'CareerPage',
    components: { BtnVoltar },
    props: {
      msg: String
    }, data() {
        return {

        }
      }
  }
</script>