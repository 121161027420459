<template>
  <div class="col-12 my-5 container-center p-2">
    <div class="col-12">
      <BtnVoltar />
    </div>
    <div class="col-12 mx-auto fs-4">{{ element.name }}</div>
    <!-- <div class="col-12 col-md-6 mx-auto mt-4 fs-4">{{ dimension }}</div> -->
    <div class="col-12 col-md-8 mx-auto mt-4">
      <iframe width="100%" height="400" v-if="video_url" :src="'https://youtube.com/embed/'+video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <div v-html="description" style="text-align:justify" class="col-12 mx-auto mt-2 fs-6"></div>
  </div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'
  export default {
    name: 'VideoPage',
    components: { BtnVoltar },
    props: {
      msg: String
    }, data() {
        return {
          element: [],
          video_url: null
        }
      }, mounted () {
        let collection = window.sessionStorage.getItem('videos')
        collection = JSON.parse(collection)

        let url = '/en/videos/'+this.$route.params.url
        let index = collection.findIndex(item => item.pageURL == url)

        this.element = collection[index]
        this.video_url = collection[index].fields.videourl
        if (this.video_url) {
          this.video_url = this.video_url.split('/')[3]
        }
        this.description = collection[index].translation.description
        console.log(this.video_url)
      }
  }
</script>