<template>
  <div class="col-12 my-5 container-center mx-auto p-2">
    <div class="col-12">
      <BtnVoltar />
    </div>
    <div class="col-12 fs-2">Schedule Tour</div>
    <div class="col-12 mt-2 fs-4">We are in the process of organizing exhibitions in public spaces for The Dalloul Art Foundation Collection.  Private visits can be arranged in some cases by filling out the form below.</div>
    <!-- <div class="col-12 fs-6 mt-4">To receive updates from DAF please consider becoming a member by clicking here >></div> -->
    <div class="row mt-4">
      <div class="col-md-12 mt-2">
        <label for="example-text-input" class="form-control-label">Full name</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-2">
        <label for="example-text-input" class="form-control-label">E-mail address</label>
        <input type="email" id="email" v-model="email" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-2">
        <label for="example-text-input" class="form-control-label">Phone Number</label>
        <input type="text" id="phone" v-model="phone" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-2">
        <label for="example-text-input" class="form-control-label">Day of the week</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="tuesday" name="day" id="tuesday">
          <label class="form-check-label" for="tuesday">Tuesday</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="thursday" name="day" id="thursday">
          <label class="form-check-label" for="thursday">Thursday</label>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-2">
        <label for="example-text-input" class="form-control-label">Time of the week</label>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="morning" name="time" id="morning">
          <label class="form-check-label" for="morning">Morning 10-12</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" value="afternoon" name="time" id="afternoon">
          <label class="form-check-label" for="afternoon">Afternoon 14-16</label>
        </div>
      </div>
    </div>
    <div v-html="description" style="text-align:justify" class="col-12 col-md-12 mt-4 fs-4"></div>
  </div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'
  export default {
    name: 'ArticlesPage',
    components: { BtnVoltar },
    props: {
      msg: String
    }, data() {
        return {
          
        }
      }
  }
</script>