<template>
  <div id="carouselExampleControls" class="carousel slide header__banner px-0" v-if="banners.length == 5" data-ride="carousel">
    <div class="carousel-indicators">
      <button data-bs-target="#carouselExampleControls" :data-bs-slide-to="index" v-bind:class="{ active: item.id == primeiro_banner }" v-for="(item, index) in banners" :key="item.id"></button>
    </div>
    <div class="carousel-inner carousel-home" role="listbox">
      <div class="carousel-item h-100" v-bind:class="{ active: banner.id == primeiro_banner }" v-for="banner in banners" :key="banner.id">
        <router-link :to="banner.pageURL" class="header-category text-decoration-none nav-link">
          <div class="carousel-caption">
            <h4 class="text-uppercase">{{ banner.name }}</h4>
          </div>
          <img :src="$baseImage+banner.image" class="w-100 h-100 object-fit-cover" style="height:100vh !important" />
        </router-link>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" v-if="banners.length > 1" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" v-if="banners.length > 1" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <div class="col-12 mb-5 container-center">
    <div class="col-12 row mx-auto">

      <div class="col-lg-3 mt-5 col-12">
        <div class="row">
          <div class="col-12 bg-dark text-center py-2 fs-3 text-white">
            <router-link :to="'/search/objects'" class="header-category text-decoration-none nav-link">
              The Collection
            </router-link>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 px-1 mt-2" role="button" v-for="object in objects" :key="object.id">
                <router-link :to="object.pageURL" class="text-decoration-none nav-link">
                  <img v-bind:src="$baseImage+ object.image" class="img-fluid cropped1">
                  <p>{{ object.name }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 mt-5 col-12 mx-auto">
        <div class="row">
          <div class="col-12 bg-dark text-center py-2 fs-3 text-white">
            <router-link :to="'/search/articles'" class="header-category text-decoration-none nav-link">
              Articles
            </router-link>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 px-1 mt-2 mb-4" role="button" v-for="article in articles" :key="article.id">
                <router-link :to="article.pageURL" class="text-decoration-none nav-link">
                  <img v-bind:src="$baseImage + article.image" class="img-fluid cropped1">
                  <div class="col-12 fw-bold">{{ article.name }}</div>
                  <div class="col-12 limite-descricao" v-html="article.translation.description" />
                  <div class="col-12 mt-2 fw-light">{{ article.publish_date_readable }}</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3 mt-5 col-12">
        <div class="row">
          <div class="col-12 bg-dark text-center py-2 fs-3 text-white">
            <router-link :to="'/search/artists'" class="header-category text-decoration-none nav-link">
              Our Artists
            </router-link>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6 px-1 mt-2" role="button" v-for="artist in artists" :key="artist.id">
                <router-link :to="artist.pageURL" class="text-decoration-none nav-link">
                  <img v-bind:src="$baseImage + artist.image" class="img-fluid cropped1">
                  <p>{{ artist.name }}</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-footer py-5">
    <div class="col-12 mb-5 container-center">
      <div class="row">
        <div class="col-12 fs-4 text-center">BECOME A MEMBER</div>
        <div class="col-12 mt-4 text-center">Join us in our endless discovery of modern and contemporary Arab Art</div>
        <div class="col-12 col-md-6 mx-auto mt-4">
          <div class="row w-100 mx-auto">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Enter your e-mail">
              <button class="btn btn-outline-secondary btn-secondary text-white bg-dark" id="button-addon2">OK</button>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  
  export default {
    name: 'HomePage',
    props: {
      msg: String
    },
    data() {
      return {
        objects: [],
        artists: [],
        articles: [],
        banners: [],
        primeiro_banner: null
      }
    },
    mounted() {
      this.getObjects()
      this.getArtists()
      this.getArticles()
      this.getNews()
      this.getVideos()
      console.log(this.banners)
    },
    methods: {
      async getObjects(){
        
        let objects = window.sessionStorage.getItem('objects')
        objects = JSON.parse(objects)

        if (objects) {
          this.objects = objects
          console.log("storage")
        } else {
          console.log("api")
          const req = await fetch(this.$baseAPI + 'objects');
          const data = await req.json()
          this.objects = data
          window.sessionStorage.setItem('objects', JSON.stringify(data))
        }
        this.primeiro_banner = this.objects[0].id
        this.banners.push(this.objects[0])
      },
      async getArtists(){

        let artists = window.sessionStorage.getItem('artists')
        artists = JSON.parse(artists)

        if (artists) {
          this.artists = artists
        } else {
          const req = await fetch(this.$baseAPI + 'artists');
          const data = await req.json();
          this.artists = data;
          window.sessionStorage.setItem('artists', JSON.stringify(data));
        }
        this.banners.push(this.artists[0])
      },
      async getArticles(){

        let articles = window.sessionStorage.getItem('articles')
        articles = JSON.parse(articles)

        if (articles) {
          this.articles = articles
        } else {
          const req = await fetch(this.$baseAPI + 'articles');
          const data = await req.json()
          this.articles = data
          window.sessionStorage.setItem('articles', JSON.stringify(data))
        }
        this.banners.push(this.articles[0])
      },
      async getNews(){

        let news = window.sessionStorage.getItem('news')
        news = JSON.parse(news)

        if (news) {
          this.news = news
        } else {
          const req = await fetch(this.$baseAPI + 'news')
          const data = await req.json()
          this.news = data
          window.sessionStorage.setItem('news', JSON.stringify(data))
        }
        this.banners.push(this.news[0])
      },
      async getVideos(){

        let videos = window.sessionStorage.getItem('videos')
        videos = JSON.parse(videos)

        if (videos) {
          this.videos = videos
        } else {
          const req = await fetch(this.$baseAPI + 'videos')
          const data = await req.json()
          this.videos = data
          window.sessionStorage.setItem('videos', JSON.stringify(data))
        }
        this.banners.push(this.videos[0])
      }
    }
  }
</script>
