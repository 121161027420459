<template>
    <div class="bg-dark py-5">
      <div class="col-12 text-center">
        <div class="social-media-buttons mx-auto">
          <a href="https://www.facebook.com/dafbeirut" target="_blank">
            <button class="btn btn-social-icon btn-outline-facebook mx-2">
              <i class="fab fa-facebook-f"></i>
            </button>
          </a>
          <a href="https://www.instagram.com/dafbeirut/" target="_blank">
            <button class="btn btn-social-icon btn-outline-instagram mx-2">
              <i class="fab fa-instagram"></i>
            </button>
          </a>
          <a href="https://www.youtube.com/@dafbeirut" target="_blank">
            <button class="btn btn-social-icon btn-outline-youtube mx-2">
              <i class="fab fa-youtube"></i>
            </button>
          </a>
          <a href="https://www.linkedin.com/company/dalloul-art-foundation" target="_blank">
            <button class="btn btn-social-icon btn-outline-linkedin mx-2">
              <i class="fab fa-linkedin"></i>
            </button>
          </a>
          <a href="https://twitter.com/dafbeirut" target="_blank">
            <button class="btn btn-social-icon btn-outline-twitter mx-2">
              <i class="fab fa-twitter"></i>
            </button>
          </a>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'OnFooter',
    props: { msg: String }
  }
</script>