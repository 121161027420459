<template>
  <div class="col-12 my-5 container-center mx-auto p-2">
    <div class="col-12 fs-2">
      <BtnVoltar />
    </div>
    <div class="col-12 mx-auto text-center fs-2">Contact</div>
    <div class="col-12 mx-auto col-md-8 mt-2 fs-6 text-center">Open from Monday - Friday from 9 am to 5 pm</div>
    <div class="col-12 text-center mx-auto mt-2 fs-6 fw-bold">Address</div>
    <div class="col-12 mx-auto col-md-8 mt-2 fs-6 text-center">Rue Madame Curie, White Tower Building, 2nd floor Beirut, Lebanon</div>
    <div class="col-12 text-center mx-auto mt-2 fs-6 fw-bold">Directions</div>
    <div class="col-12 mx-auto col-md-8 mt-2 fs-6 text-center">Facing College Protestante Francais, the building before Urbanista and Siran Towers Bloc B</div>
    <div class="col-12 text-center mx-auto mt-2 fs-6 fw-bold">Find Us</div>
    <div class="col-12 col-md-8 mx-auto mt-2">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3311.927149977945!2d35.475982!3d33.89153!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f1116ce2c0dc1%3A0xa01d933cc0b79f53!2sWhite%20Tower%20Building!5e0!3m2!1spt-BR!2sbr!4v1678902805640!5m2!1spt-BR!2sbr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="col-12 text-center mx-auto mt-2 fs-6 fw-bold">For General Inquiries</div>
    <div class="col-12 mx-auto col-md-8 mt-2 fs-6 text-center">info@dafbeirut.org</div>
    <div class="col-12 mx-auto col-md-8 mt-2 fs-6 text-center">(+961) 1 791 229</div>
    <div class="col-md-8 col-12 mx-auto row mt-4">
      <div class="col-12 mt-2">
        <label for="example-text-input" class="form-control-label">Full name</label>
        <input type="text" id="full_name" v-model="full_name" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-2">
        <label for="example-text-input" class="form-control-label">E-mail address</label>
        <input type="email" id="email" v-model="email" class="form-control" />
      </div>
      <div class="col-md-6 col-12 mt-2">
        <label for="example-text-input" class="form-control-label">Phone Number</label>
        <input type="text" id="phone" v-model="phone" class="form-control" />
      </div>
      <div class="col-12 mt-4">
        <label for="example-text-input" class="form-control-label">Your Message</label>
        <textarea id="full_name" v-model="full_name" class="form-control" />
      </div>
    </div>
  </div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'
  export default {
    name: 'ContactPage',
    components: { BtnVoltar },
    props: {
      msg: String
    }, data() {
        return {

        }
      }
  }
</script>