<template>
  <div class="col-12 my-5 container-center">
    <div class="col-12 px-2">
      <BtnVoltar />
    </div>
    <div class="col-12">
      <div class="row w-100 mx-auto">
        <div class="input-group mb-3">
          <div class="col-12 fs-4 my-2">The Collection</div>
          <input type="text" v-model="palavra" class="form-control" placeholder="Search Art, Artists, News & Articles on dafbeirut.org">
          <button class="btn btn-outline-secondary btn-secondary text-white bg-dark" @click="buscar()">Search</button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row w-100 mx-auto">
        <div class="col-lg-4 col-sm-6 col-12 mt-5" role="button" v-for="object in objects" :key="object.id">
          <router-link :to="object.pageURL" class="text-decoration-none nav-link">
            <img v-bind:src="$baseImage+ object.image" class="img-fluid cropped1">
            <div class="col-12 fs-5">{{ object.name }}</div>
            <div class="col-12 fw-light">
              {{ object.page_parent.name }}, 
              {{ object.page_parent.fields.nationality }}, 
              {{ object.fields.yearcreated }}
              <br>{{ object.fields.dimension1._text }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
</div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'
  import router from '../router'

  export default {
    name: 'TheCollection',
    components: { BtnVoltar },
    props: {
      msg: String
    },
    data() {
      return {
        objects: [],
        palavra: null
      }
    }, 
    mounted() {
      this.getObjects()
    },
    methods: {
      async getObjects(){

        let collection = window.sessionStorage.getItem('objects')
        collection = JSON.parse(collection)

        if (collection.length == 0 || !collection) {
          const req = await fetch(this.$baseAPI + 'objects')
          const data = await req.json()
          this.objects = data
          window.sessionStorage.setItem('objects', JSON.stringify(data))
        } else {
          this.objects = collection
        }
        console.log(this.objects)

      },
      async buscar (){
        router.push('/search/all/'+this.palavra)
      }
    }
  }
</script>