
<template>
  <div class="col-12 my-5 container-center ">
    <div class="col-12 px-2">
      <BtnVoltar />
    </div>
    <div class="col-12">
      <div class="row w-100 mx-auto">
        <div class="input-group mb-3">
          <div class="col-12 fs-4 my-2">Artists</div>
          <input type="text" v-model="searchKey" class="form-control"
            placeholder="Search Art, Artists, News & Articles on dafbeirut.org">
          <button class="btn btn-outline-secondary btn-secondary text-white bg-dark" @click="buscar()">Search</button>
        </div>
      </div>
    </div>
    <div class="col-12 " >
     
      <div class="row w-100 mx-auto">
        <div  class=" col-lg-4 col-sm-6 col-12 mt-5" role="button" v-for="item in artists" :key="item.id">
          <router-link :to="item.pageURL" class="text-decoration-none nav-link">
            <img v-bind:src="$baseImage + item.image" class="img-fluid cropped1">
            <div class="col-12">{{ item.name }}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="col-12 text-center">
      <button class="btn btn-dark" v-on:click="loadMore()">View All Artists</button>
    </div>
    <div class="list-group-wrapper">
      <transition name="fade">
        <div class="loading" v-show="loading">
          <span class="fa fa-spinner fa-spin"></span> Loading
        </div>
      </transition>
      
    </div>
  </div>
</template>

<script>

import BtnVoltar from '@/components/BtnVoltar.vue'
import router from '../router'

var count = 0

export default {
  name: 'ArtistsPage',
  components: { BtnVoltar },
  props: {
    msg: String
  },
  data() {
    return {
      loading: false,
      nextItem: 1,
      artists: [],
      searchKey: null,
    }
  },
  mounted() {

    this.getObjects()

    window.addEventListener("scroll", this.handleScroll);
   
   
  },
  methods: {
    handleScroll() {
      if (  window.scrollY + window.innerHeight >= document.body.scrollHeight  ) {
        console.log('handleScroll')
        this.loadMore()
      }
    },
   
    async getObjects() {

      count = 0;

      var self = this;
      self.busy = true;
      console.log('loading start... ' + count);

      // let collection = window.sessionStorage.getItem('artists')
      // collection = JSON.parse(collection)

      // if (!collection || collection.length == 0  ) {
      const req = await fetch(this.$baseAPI + 'artists?scroll=yes&start=' + count)
      const data = await req.json()
      this.artists = data
      console.log('artists')
      console.log(this.artists)

     
      window.sessionStorage.setItem('artists', JSON.stringify(data))

      // } else {
      //   this.artists = collection
      // }

    },
    async loadMore() {

      // var self = this;
      // self.busy = true;

      count = count + 12

      console.log('loading in... ' + count);

      // let collection = window.sessionStorage.getItem('artists')
      // collection = JSON.parse(collection)

      // if (collection.length == 0 || !collection) {
      const req = await fetch(this.$baseAPI + 'artists?scroll=yes&start=' + count)
      const data = await req.json()

      const newData = this.artists.concat(data)

      this.artists = newData;
      console.log('artists')
      console.log(this.artists)

     
      window.sessionStorage.setItem('artists', JSON.stringify(newData))
      // } else {
      //   this.artists = collection
      // }

    },
  },
  async buscar() {
    router.push('/search/all/' + this.searchKey)
  }
}
</script>