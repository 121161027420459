<template>
  <div class="col-12 my-5 container-center mx-auto p-2">
    <div class="col-12 fs-2">
      <BtnVoltar />
    </div>
    <div class="col-12 fs-2">Help</div>
    <div class="col-12 mt-2 fs-6">Thank you for visiting our website, we’re happy to receive you and hope you find it useful. We’d like to point out a few things that may enhance your experience. This help page can be accessed at any time on dafbeirut.org/help, from the menu or the icon to the left of the footer.</div>
    <div class="col-12 mt-2 fs-6">While you do not need to register to view the content on our site we do need you to register for free and become a member to interact and receive updates from us. Following are some of the interactions you can enjoy after becoming a member:</div>
    <div class="col-12 mt-2 fs-6">Clicking or tapping on the plus or FOLLOW symbol for an Artist will add them to your favourite Artist list. If you have selected to receive notifications on your profile settings, then you will receive an email from DAF with any new content the Artist you follow is tagged to.</div>
    <div class="col-12 mt-2 fs-6">Clicking or tapping on the heart symbol for an Artwork will add it to your favourite Artwork list. This also gives us and other visitors an idea on which are the most popular Artwork that resonate with our members and can possibly direct future acquisitions.</div>
    <div class="col-12 mt-2 fs-6">We welcome public perspectives on any of the Artwork in our Collection. Please be respectful and constructive with your comments. You can click on the comment symbol to view perspectives on the Artwork from other members without being a member yourself.</div>
    <div class="col-12 mt-2 fs-6">Please do consider sharing our content on your social media which can be accessed from this icon.</div>
    <div class="col-12 mt-2 fs-6">The help page can be accessed at anytime by clicking this icon or from the menu.  We will continue to update the page as we receive feedback.</div>
    <div class="col-12 mt-2 fs-6"><span class="fw-bold">Notification preferences</span> - Once you have subscribed and are logged in, you have the option to configure precisely the type of communications you wish to receive from us by visiting your 'My Profile' page that is linked from the MENU that is opened when you click or tap on the logo at the top of the page.</div>
    <div class="col-12 mt-2 fs-6"><span class="fw-bold">Feedback</span> - We welcome and encourage all feedback on the website. If you have any feedback you would like to share with us, please visit the contact page</div>
  </div>
</template>

<script>

  import BtnVoltar from '@/components/BtnVoltar.vue'
  export default {
    name: 'HelpPage',
    components: { BtnVoltar },
    props: {
      msg: String
    }, data() {
        return {

        }
      }
  }
</script>