<template>
  <button class="btn btn-outline-secondary btn-secondary text-white bg-dark" id="button-back" @click="$router.back()">
    <i class="fa-solid fa-arrow-left"></i> Back
  </button>
</template>

<script>

  import router from "../router";
  export default {
    name: 'BtnVoltar',
    props: { msg: String },
    methods: {
      async goBack () {
        router.go(-1)
      }
    }
  }
</script>